// @ts-nocheck
import { useTranslation } from "react-i18next";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { SetStateAction, useEffect, useState } from "react";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { BlockService } from "../../../block/service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { SingleValue } from "react-select";
import { ProductsService } from "../../service";
import { ContentEditLanguage } from "./ContentEditLanguage";
import Select from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { WorkflowService } from "../../../workflow/service";
import { GlossaryService } from "../../../glossary/service";
interface IProductsActionsProps {
  allBlocks: any[];
  tabLanguages?: any;
  isTranslator?: boolean;
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  recommendedLanguage?: string;
  translationLangTab?: string | undefined;
  handleCloseContent?: (() => void | undefined) | undefined;
  setTriggerUpdate?: any;
  currentProduct: IProductsList | IProducts;
  changeHandler: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    language: string
  ) => void;
  handleSubmit: (event: React.FormEvent, language: string) => void;
  setCurrentProduct: React.Dispatch<SetStateAction<IProductsList | undefined>>;
}

export const ContentActions = ({
  allBlocks,
  tabLanguages = [],
  isTranslator = false,
  handleCloseContent,
  selectedjiProductId,
  setTriggerUpdate,
  currentProduct,
  setCurrentProduct,
  handleSubmit,
  isWorkflowUpdate = false,
  translationLangTab,
  recommendedLanguage,
}: IProductsActionsProps) => {
  const { search } = useLocation();
  const location = useLocation();
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const { state: content }: any = useLocation();
  const urlLang =
    search.split("=").at(-1) ?? 0 !== 0
      ? search.split("=").at(-1)
      : localStorage.getItem("language");
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const { id, lng } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = currentProduct?.workflow?.language[0] ?? urlLang;
  const [activeTab, setActiveTab] = useState();
  const [certainBlocks, setCertainBlocks] = useState([]);

  let result = "";
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [field, setField] = useState("");
  // useEffect(() => {
  //   setActiveTab(lng ?? currentLanguage);
  // }, [lng, currentLanguage]);
  //   setActiveTab(translationLangTab ?? urlLang ?? currentLanguage);
  // }, [urlLang, currentLanguage]);
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const handleSelectBlock = (
    selectedValue: MultiValue<{ label: string; value: number; isAdd: boolean }>
  ) => {
    const selectedValues: number[] = [];
    selectedValue.forEach((value) => selectedValues.push(value.value));

    ProductsService.updateProductBlockById(
      { ...currentProduct, block: selectedValues } as any,
      Number(id)
    )
      .then((response) => {
        setCurrentProduct(response);
        SuccessToast(
          `${t("toasts.successfully_updated_block_of")} ${response.title}`
        );
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    if (currentProduct?.id && !!allBlocks.length) {
      const certain = getCertainBlocks(currentProduct?.block as any, allBlocks);
      setCertainBlocks(certain as any);
    }
  }, [currentProduct, JSON.stringify(allBlocks)]);
  const changeActiveTab = (lng: string, tab: string) => {
    // navigate(`/app/products/content/${lng}/${id}`);
    if (!isTranslator) {
      setActiveTab(lng);
      navigate(`?lang=${lng}`);
      id &&
        tab &&
        ProductsService.getProductLangById(
          Number(isWorkflowUpdate ? selectedProductId : id),
          tab,
          lng
        )
          .then((response) => {
            setCurrentProduct(response);
          })
          .catch((error) => {
            ErrorToast(error);
            navigate(-1);
          });
    } else {
      setActiveTab(lng);
      // WorkflowService.getTranslationProductById(Number(id), Number(selectedProductId))
      //   .then((response) => {
      //
      //     setCurrentProduct(response);
      //   })
      //   .catch((error) => {
      //     ErrorToast(error);
      //     navigate(-1);
      //   });
      // ProductsService.getProductLangById(Number(isWorkflowUpdate ? selectedProductId : id), tab, lng)
      //   .then((response) => {
      //     setCurrentProduct(response);
      //   })
      //   .catch((error) => {
      //     ErrorToast(error);
      //     navigate(-1);
      //   });
    }
  };
  console.log(activeTab, "activeTab");

  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }
  const clientGlossary = () => {
    if (workflowData && activeTab) {
      GlossaryService.getAllGlossary({
        client: workflowData?.client?.id as number,
        language: activeTab,
        page: 1,
        perPage: 0,
      })
        .then((response) => {
          const { data } = response;
          const glossaries = data.map((glossary) => {
            return {
              [glossary.word]: glossary.alternative,
            };
          });
          const glossaryObject = glossaries.reduce((result, currentObject) => {
            return Object.assign(result, currentObject);
          }, {});
          const glossaryClientObject = { client: workflowData?.client?.name };
          const objectStorage = JSON.stringify(glossaryObject);
          const objectClient = JSON.stringify(glossaryClientObject);
          localStorage.setItem("glossary", objectStorage);
          localStorage.setItem("glossaryClient", objectClient);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const getCertainBlocks = (block: any, listOfAllBlocks: any) => {
    const data: any[] = [];
    listOfAllBlocks?.forEach((item: any) => {
      if (block.some((a: number) => a === item.id)) {
        data.push(item);
      }
    });
    return data;
  };
  const handleAiVariation = () => {
    setIsLoading(true);
    id &&
      ProductsService.addNewAiVaraiton(+id)
        .then((response) => {
          setTriggerUpdate(true);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoading(false));
  };
  const handleTranslate = () => {
    ProductsService.postTranslation({
      text: currentProduct?.translation?.[sourceLanguage]?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        if (
          currentProduct?.translation?.[targetLanguage]?.[field] === null ||
          currentProduct?.translation?.[targetLanguage]?.[field] ===
            undefined ||
          currentProduct?.translation?.[targetLanguage]?.[field] === "" ||
          currentProduct?.translation?.[targetLanguage] === null
        ) {
          result = response;
        } else {
          result = currentProduct?.translation?.[targetLanguage]?.[
            field
          ].concat(" ", response);
        }
        setCurrentProduct((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              [field]: result,
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const changeHandlerEditorBlock = (
    value: string,
    language: string,
    blockId: number
  ) => {
    setCurrentProduct((prevState: any) => ({
      ...prevState,
      translation: {
        ...prevState?.translation,
        [language]: {
          ...prevState?.translation?.[language],
          blocks: {
            ...prevState?.translation?.[language]?.blocks,
            [blockId]: value,
          },
        },
      },
    }));
  };
  useEffect(() => {
    if (window.location.pathname?.includes("products/edit-content")) {
      setActiveTab(urlLang);
      console.log("products/edit-content");
    } else if (window.location.pathname?.includes("workflow/step5")) {
      setActiveTab(urlLang);
    } else {
      console.log("workflowLang");
      console.log(localStorage.getItem("workflowLang"));
      setActiveTab(
        localStorage.getItem("translationNextLang") === ""
          ? localStorage.getItem("workflowLang") ?? urlLang
          : localStorage.getItem("translationNextLang")
      );
    }
  }, [
    localStorage.getItem("workflowLang"),
    localStorage.getItem("translationNextLang"),
  ]);
  useEffect(() => {
    if (workflowData?.id && activeTab) {
      clientGlossary();
    }
  }, [activeTab]);
  const changeHandlerEditor = (
    language: string,
    value: string,
    eventName: string
  ) => {
    setCurrentProduct((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [eventName]: value,
        },
      },
    }));
  };

  const handleTranslateBlock = () => {
    ProductsService.postTranslation({
      text: currentProduct?.translation?.[sourceLanguage]?.blocks?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        if (
          currentProduct?.translation?.[targetLanguage]?.blocks?.[field] ===
            null ||
          currentProduct?.translation?.[targetLanguage]?.blocks?.[field] === ""
        ) {
          result = response;
        } else {
          result = currentProduct?.translation?.[targetLanguage]?.blocks?.[
            field
          ].concat(" ", response);
        }
        setCurrentProduct((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              blocks: {
                ...prev?.translation?.[targetLanguage]?.blocks,
                [field]: result,
              },
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  // "api/product/edit/${activeTab}";
  // useEffect(() => {
  //   if (recommendedLanguage) {
  //     setActiveTab(recommendedLanguage);
  //   } else {
  //     setActiveTab(translationLangTab);
  //   }
  // }, [recommendedLanguage]);
  return (
    <>
      {!isTranslator ? (
        <div className="card ">
          <div className="card-body">
            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0">
              {translationLanguageEnum?.map((language) => {
                return (
                  //products/edit-content second col
                  <li
                    className="nav-item custom-basis"
                    key={language}
                    onClick={() => changeActiveTab(language, "CONTENT")}
                  >
                    <a
                      className={`nav-link ${
                        activeTab === language ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href={`#${language}`}
                      // href={`?lang=${language}`}
                      role="tab"
                      aria-selected={language === currentLanguage}
                    >
                      {t(`products.content_${language}`)}
                    </a>
                  </li>
                );
              })}
            </ul>

            <div className="tab-content">
              {translationLanguageEnum?.map((language) => {
                return (
                  <>
                    <div
                      className={`tab-pane  ${
                        activeTab === language ? "active" : ""
                      }`}
                      id={language}
                      role="tabpanel"
                    >
                      <ContentEditLanguage
                        setTriggerUpdate={setTriggerUpdate}
                        handleCloseContent={handleCloseContent}
                        changeHandlerEditor={changeHandlerEditor}
                        changeHandlerEditorBlock={changeHandlerEditorBlock}
                        handleAiVariation={handleAiVariation}
                        isLoading={isLoading}
                        handleTranslateBlock={handleTranslateBlock}
                        handleSelectSingle={handleSelectSingle}
                        handleSelectSingleFormality={
                          handleSelectSingleFormality
                        }
                        handleTranslate={handleTranslate}
                        currentProduct={currentProduct}
                        certainBlocks={certainBlocks}
                        language={language}
                        handleSubmit={handleSubmit}
                        allBlocks={allBlocks}
                        isTranslator={isTranslator}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="card ">
          <div className="card-body">
            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0">
              {tabLanguages?.map((language) => {
                return (
                  //workflow content second col
                  <li
                    className="nav-item custom-basis"
                    key={language}
                    onClick={() => changeActiveTab(language, "CONTENT")}
                  >
                    <a
                      className={`nav-link ${
                        activeTab === language ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href={`#${language}`}
                      // href={`?lang=${language}`}
                      role="tab"
                      aria-selected={language === currentLanguage}
                    >
                      {t(`products.content_${language}`)}
                    </a>
                  </li>
                );
              })}
            </ul>

            <div className="tab-content">
              {tabLanguages?.map((language) => {
                return (
                  <>
                    <div
                      className={`tab-pane  ${
                        activeTab === language ? "active" : ""
                      }`}
                      id={language}
                      role="tabpanel"
                    >
                      <ContentEditLanguage
                        setTriggerUpdate={setTriggerUpdate}
                        handleCloseContent={handleCloseContent}
                        changeHandlerEditor={changeHandlerEditor}
                        changeHandlerEditorBlock={changeHandlerEditorBlock}
                        handleAiVariation={handleAiVariation}
                        isLoading={isLoading}
                        handleTranslateBlock={handleTranslateBlock}
                        handleSelectSingle={handleSelectSingle}
                        handleSelectSingleFormality={
                          handleSelectSingleFormality
                        }
                        handleTranslate={handleTranslate}
                        currentProduct={currentProduct}
                        certainBlocks={certainBlocks}
                        language={language}
                        handleSubmit={handleSubmit}
                        allBlocks={allBlocks}
                        isTranslator={isTranslator}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {!!allBlocks?.length && !isTranslator && (
        <div className="card">
          <div className="card-body">
            <div className="row">
              <>
                <div className="col-md-12 my-3">
                  <label htmlFor="blocks">{t("products.blocks")}</label>
                  <Select
                    className="react-select-field"
                    theme={themeSelect}
                    value={certainBlocks?.map(
                      (certainValue: { name: string; id: number }) => ({
                        label: certainValue?.name,
                        value: certainValue?.id,
                      })
                    )}
                    options={allBlocks?.map(
                      (value: { name: string; id: number }) => ({
                        label: value?.name,
                        value: value?.id,
                      })
                    )}
                    isMulti
                    onChange={(e) => {
                      handleSelectBlock(
                        e as MultiValue<{
                          label: string;
                          value: number;
                          isAdd: boolean;
                        }>
                      );
                    }}
                    key={currentProduct?.id}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
