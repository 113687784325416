import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useEffect, useState } from "react";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IChatGptPrompt } from "../dto/IChatGptPrompt";
import { GoogleSearchPromptService } from "../service";
import { LoadingAnimation } from "../../../shared/components/Loading";
// import { ContentService } from "../service";
// import { IContent } from "../dto/IContent";

interface IChatGptPromptListProps {
  accessRights: string[];
  promptsList: any;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  totalPages: number;
  page: number;
  handlePages: (page: number) => void;
  editHandler: (crawlerId: number) => void;
}

export const ChatGptPromptList = ({
  accessRights,
  promptsList,
  totalPages,
  page,
  handlePages,
  setTriggerUpdate,
  editHandler,
}: IChatGptPromptListProps) => {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setselectedPrompt] = useState<IChatGptPrompt>();
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const deleteHandler = () => {
    selectedPrompt?.id &&
      GoogleSearchPromptService.deletePrompt(selectedPrompt?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedPrompt?.title}`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {`${t("google_search.google_search_prompt_list")}`}
          </h4>
        </div>
      </div>
      <div className="card-body">
        {promptsList == undefined ? (
          <LoadingAnimation />
        ) : promptsList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("global.title")}`}</th>
                  <th scope="col">{`${t("google_search.pages")}`}</th>
                  {(accessRights?.includes("chat_gpt_prompts_can_edit") ||
                    accessRights?.includes("chat_gpt_prompts_can_delete")) && (
                    <th scope="col">{`${t("global.actions")}`}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {promptsList?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td className="text-start">{prompt?.title ?? "-"}</td>
                      <td>{prompt?.pages}</td>
                      {(accessRights?.includes("chat_gpt_prompts_can_edit") ||
                        accessRights?.includes(
                          "chat_gpt_prompts_can_delete"
                        )) && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {accessRights?.includes(
                              "chat_gpt_prompts_can_edit"
                            ) && (
                              <button
                                className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                onClick={() => editHandler(prompt?.id)}
                              >
                                <i className="ri-edit-2-fill me-1" />
                                {`${t("global.edit")}`}
                              </button>
                            )}
                            {accessRights?.includes(
                              "chat_gpt_prompts_can_delete"
                            ) && (
                              <button
                                className="btn btn-sm btn-danger d-flex align-items-center"
                                onClick={() => {
                                  handleShow();
                                  setselectedPrompt(prompt);
                                }}
                              >
                                <i className="ri-delete-bin-fill me-1" />
                                {`${t("global.delete")}`}
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.title}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
