import {
  DATA_ENRICHMENT_URL,
  GOOGLE_SEARCH_PROMPT_URL,
} from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IChatGptPromptCreate, IChatGptPromptList } from "./dto/IChatGptPrompt";

export const GoogleSearchPromptService = {
  getAllPrompts: (params: IChatGptPromptList) =>
    requests.get(`${GOOGLE_SEARCH_PROMPT_URL}`, params),
  createPrompt: (params: IChatGptPromptCreate) =>
    requests.post(`${GOOGLE_SEARCH_PROMPT_URL}`, params),
  getPrompt: (id: number) => requests.get(`${GOOGLE_SEARCH_PROMPT_URL}/${id}`),
  updatePrompt: (params: IChatGptPromptCreate, id: number) =>
    requests.put(`${GOOGLE_SEARCH_PROMPT_URL}/${id}`, params),
  deletePrompt: (id: number) =>
    requests.delete(`${GOOGLE_SEARCH_PROMPT_URL}/${id}`),
  enrichProduct: (params: any) =>
    requests.put(`${DATA_ENRICHMENT_URL}`, params),
};
